import {Link} from 'react-router-dom';
import {useState, useEffect} from 'react'

import bnrlogo from '../assets/images/logo.png'
import girl from '../assets/images/girl.png';
import girls from '../assets/images/girls.jpg';
import banner from '../assets/images/banner.jpg';

import Slider from '../components/Slider';
import '../assets/css/home.css';


// import banner from '../assets/images/banner.jpg' [<img src={banner} className="App-logo" alt="logo" />]

function Home(){

useEffect(() => {
   document.title = "Home"
}, []);


        const colar = {
            "clr_id":"3",
            "clr_img":"col-1.png",
            "clr_price":1,
        };
        localStorage.setItem("collar", JSON.stringify(colar));


        const fabr = {
            "fabid":"11",
            "faburl":"https://al-qusoor.syoware-testing.com/product-image/frock/color-1.png",
            "fabprice":1,
        };
        localStorage.setItem("fabric", JSON.stringify(fabr));


    return (
        <div className="home-page">
        <div className="banner">
            <div className="imgwrap"><img src={banner}/></div>
                <div className="bnr-text">
                    <div className="container">
                        <div className="inner">
                        <p className="bnr-logo">
                        <img src={bnrlogo} />
                        </p>
                         

                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
            <div className="shop-btn"><Link to="/size" className="btn btn-outline">Shop</Link></div>

            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 middle">
                        <div className="inner al-text">
                            <p>Meticulously created in our studio in the United Arab Emirates, down to the last stitch. Our one-of-a-kind dresses are all crafted especially for your princess from the finest materials by skilled tailors.</p>
                            <p>Our twirl-ready dresses are created to bring out her regal beauty while letting a glimpse of her fun charm show through. They are meant to make her grin sweetly.</p>
                            <p>We dress your little girl with exquisitely handcrafted dresses that will make her shine wherever she goes, from formal events to family summer vacations.</p>
  
                        </div>
                    </div>
                    <div className="col-md-6">
                        <img src={girl} className="App-logo" alt="logo" />
                    </div>
                </div>
            </div>
            <div className="row nospace">
                <div className="col-md-6 middle bluebg">
                    <div className="inner">
                        <h3>It is one-of-a-kind and charming.</h3>
                        <p>Designed to get people's attention. Limited supply. Handmade with meticulous attention to detail and flawless stitching. 100% organic cotton is used to line the garment.</p>
                    </div>
                </div>
                <div className="col-md-6">
                        <img src={girls} className="App-logo" alt="logo" />
                </div>
            </div>
            <div id="about" className="container hmtext">
                <div className="sub-title">
                    <h1>About us</h1>
 
                </div>
                <div className='abt-text'>
                <p>Ameerati was based on a passion for femininity, craftsmanship, refinement, and opulent textiles. Each costume, in my opinion, should be a piece of art, lovingly constructed from start to end. My garments, I hope, will be passed down through generations or serve as a lovely childhood remembrance.</p>

                <p>As Ameerati's founder, I dedicate all of my time and attention to creating the gowns, finding the best materials, and picking the decorations. I aim to create things that are as one-of-a-kind as the person. Each piece is meticulously created to last a lifetime and generate wonderful memories.</p>

                <p>Ameerati is a brand that specialises in unique and appealing girl&#39;s clothes. Experts in Abu Dhabi designed and handmade this piece from the best designer fabrics. Each item is handcrafted.</p>
                </div>

            </div>

<Slider />
<div id="hm-contact" className="contact-section">
    <div className="cloud num1"></div>
    <div className="cloud num2"></div>
    <div className="cloud num3"></div>
    <div className="flymove">
        <div className="btsize">
            <div className="butterfly pink">
                <div className="wing left"></div>
                <div className="wing right"></div>
                <div className="fly"></div>
            </div>
        </div>
    </div>
    
    <div className="item middle inspired-wrap">
        <div className="container">
            <div className="inner">
            <p>Ameerati was inspired by a desire to create stylish and appealing girl's gowns that highlight the wearer's beauty. Designed and produced in Abu Dhabi in limited numbers using the best quality textiles.</p>
            </div>
        </div>
    </div>
</div>   
        </div>
    )
}
export default Home;