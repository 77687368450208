import React, { useEffect, useState } from "react";
import { Link, useNavigate ,Navigate } from "react-router-dom";
import { Logout } from "../redux/feature/authSlice";
import { useDispatch } from "react-redux";

const Myaccount = () => {
    const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [loggedIn, setLoggedIn] = useState(true);

//   useEffect(() => {
//     if (sessionStorage.getItem("token")) {
//       const user = sessionStorage.getItem("username");
//       setData(user);
//       setLoggedIn(true);
//     } else {
//       setLoggedIn(false);
//     }
//   }, []);

  const handleLogout = () => {
    dispatch(Logout()) 
    navigate("/");
  };

  return (
    <div id="main-content">
    <div className="page-title">
                    <div className="container"></div>
                </div>
    <div className="container">
      <div className="row col-8"> Welcome {data}!<br /><br /></div>
      <button onClick={() => handleLogout()}> Logout</button>{" "}
      <Link to={"/contact"}><button>Redirect</button> </Link>
    </div>
    </div>
  );
};

export default Myaccount;
