import React from "react";
import { useNavigate,Navigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { FormControl, FormGroup, Form } from "react-bootstrap";
import toast from "react-hot-toast";
import { login } from "../redux/feature/authSlice";
import { useDispatch, useSelector } from "react-redux";
import frock from '../assets/images/frock.png';
const validationSchema = yup.object({
    username: yup
        .string("Enter your username")
        .required("Username is required"),
    password: yup
        .string("Enter your password")
        .min(6, "Password should be of minimum 6 characters length")
        .required("Password is required"),
});

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isAuthenticated } = useSelector((state) => state.auth);

    const formik = useFormik({
        initialValues: {
            username: "",
            password: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const rawData = JSON.stringify({
                name: values?.username,
                pass: values?.password,
            });
            dispatch(login({ rawData })).then((data) => {
                if (data.payload?.current_user) {
                    toast.success("Login Successfully !!");
                    navigate("/myaccount");
                }
            });
        },
    });

    //   };

    return (
        <div id="main-content">
        <div className="contact-page">
      <div className="page-title">
          <div className="container"></div>
      </div>
      <div className='blur-bg'>
        <div className="container pt-40">
            <div class="row">
                <div class="col-md-6">
                    <div class="inner login-form">
                        <form
                        onSubmit={formik.handleSubmit}
                        autoComplete="off"
                        className="mt-3 mt-md-5 lgn-form"
                    >
                    <h2>Login</h2>
                        <div className="form-group">
                            <FormGroup
                                controlId="username"
                                className="position-relative"
                            >
                                <FormControl
                                    type={"text"}
                                    className="form-control"
                                    placeholder="Enter username"
                                    size="lg"
                                    value={formik.values.username}
                                    onChange={formik.handleChange}
                                    isInvalid={
                                        formik.touched.username &&
                                        Boolean(formik.errors.username)
                                    }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                    {formik.touched.username &&
                                        formik.errors.username}
                                </Form.Control.Feedback>
                            </FormGroup>
                             </div>
                        <div className="form-group mt-4">
                            <FormGroup
                                controlId="password"
                                className="position-relative"
                            >
                                <FormControl
                                    type={"password"}
                                    className="form-control"
                                    placeholder="Enter password"
                                    size="lg"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    isInvalid={
                                        formik.touched.password &&
                                        Boolean(formik.errors.password)
                                    }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                    {formik.touched.password &&
                                        formik.errors.password}
                                </Form.Control.Feedback>
                            </FormGroup>
                             </div>

                        <div className="btn-wrap mt-4 center">
                            <button type="submit" className="btnlg">
                                Login{" "}
                            </button>
                        </div>
                    </form>       
                    </div>
                </div>
                <div className='col-md-6 text-center'>
                    <img className="logn-img" src={frock} />
                </div>

            </div>
        </div>
      </div>
    </div>
        </div>
    );
};

export default Login;
