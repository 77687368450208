import React from "react";
import { NavLink, Link } from "react-router-dom";

import logo from "../assets/images/logo.png";

function Header() {
    const scrollAbout = () => {
        setTimeout(function() {
        const section = document.querySelector("#about");
        section.scrollIntoView({ behavior: "smooth", block: "start" });
    }.bind(this), 1000);
    };

    return (
        <div className="header">
            <div className="container">
                <div className="logowrap">
                    <Link to="/">
                        <img src={logo} className="App-logo" alt="logo" />
                    </Link>
                </div>
                <ul className="main-menu">
                    <li>
                        <NavLink activeclassname="active" to="/">
                            Home
                        </NavLink>
                    </li>
                    <li onClick={scrollAbout}>
                        <Link to="/">About us</Link>
                    </li>
                    <li>
                        <NavLink activeclassname="active" to="/size">
                            Shop
                        </NavLink>
                    </li>
                    <li>
                        <NavLink activeclassname="active" to="/sizechart">
                            Size Chart
                        </NavLink>
                    </li>
                    {/* <li><NavLink activeclassname="active" to="/stock">Stock</NavLink></li> */}
                    <li>
                        <NavLink activeclassname="active" to="/contact">
                            Contact Us
                        </NavLink>
                    </li>
                    <li>
                        <NavLink activeclassname="active" to="/myaccount">
                            My account
                        </NavLink>
                    </li>
                    <li>
                        <NavLink activeclassname="active" to="/cart">
                            <i className="cart-icon"></i>
                        </NavLink>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Header;
