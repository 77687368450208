import { baseImage, baseUrl } from "./config";


export const API_LOGIN = baseUrl + "user/login?_format=json";






