import { useEffect} from 'react'


function About(){

useEffect(() => {
   document.title = "About Us"
}, []);


    return (
        <div className="about-page">

            <div className="page-title">
                <div className="container"><h1>About Us</h1></div>
            </div>

            <div className="container">
                <h1>About Us</h1>
                <p>Ameerati was based on a passion for femininity, craftsmanship, refinement, and opulent textiles. Each costume, in my opinion, should be a piece of art, lovingly constructed from start to end. My garments, I hope, will be passed down through generations or serve as a lovely childhood remembrance.</p>

                <p>Ameerati was based on a passion for femininity, craftsmanship, refinement, and opulent textiles. Each costume, in my opinion, should be a piece of art, lovingly constructed from start to end. My garments, I hope, will be passed down through generations or serve as a lovely childhood remembrance.</p>

                <p>Ameerati is a brand that specialises in unique and appealing girl's clothes. Experts in Abu Dhabi designed and handmade this piece from the best designer fabrics. Each item is handcrafted.</p>
                </div>









        </div>
    )

}
export default About;