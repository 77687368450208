
export const baseUrl =  "https://back-end.ameeratifashions.com/"
export const baseImage =  "https://back-end.ameeratifashions.com/"

// export const baseUrl =  "http://195.35.22.219:3334/admin/"
// export const baseImage =  "http://195.35.22.219:3334"


export const getToken = () => {
  return JSON.parse(localStorage.getItem('token')) || null;
}

