import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-hot-toast";
import { API_LOGIN, API_LOGOUT } from "../../utils/APIConstant";

export const login = createAsyncThunk("login", async ({ rawData }) => {
    try {
        const res = await axios.request({
            method: "POST",
            url: API_LOGIN,
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
            },
            data: rawData,
        });
        return res.data;
    } catch (err) {
        toast.error(err.response.data.error);
        return err.response.data;
    }
});

// export const signup = createAsyncThunk("signup", async ({ values }) => {
//     try {
//         const res = await axios.request({
//             method: "POST",
//             url: API_LOGIN,
//             headers: {
//                 Accept: "application/json",
//                 "Content-type": "application/json",
//             },
//             data: JSON.stringify(values),
//         });
//         toast.success(res.data.message, {
//             position: "top-right",
//         });
//         return res.data;
//     } catch (err) {
//         toast.error(err.response.data.error);
//         return err.response.data;
//     }
// });

export const Logout = createAsyncThunk("user/logout", async (token) => {
    // const res = await axios.request({
    //     method: "get",
    //     url: API_LOGOUT,
    //     headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${token}`,
    //     },
    // });
    return ;
});

const authSlice = createSlice({
    name: "auth",
    initialState: {
        loading: false,
        error: null,
        user: null,
        token: null,
        logout_token: null,
        isAuthenticated: false,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(login.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(login.fulfilled, (state, action) => {
            state.loading = false;
            if (action.payload?.current_user) {
                state.user = action.payload.current_user;
                state.token = action.payload.csrf_token;
                state.logout_token = action.payload.logout_token;
                state.isAuthenticated = true;
            }
        });
        builder.addCase(login.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(Logout.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(Logout.fulfilled, (state, action) => {
            state.loading = false;
            state.isAuthenticated = false;
            state.user = "";
            state.token = "";
            sessionStorage.removeItem("persist:root");
        });
        builder.addCase(Logout.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});

export default authSlice.reducer;
