import API from "./axios";

function handleApiError(error) {
  let data = error?.response?.data ?? { message: error?.message };
  return { success: false, data, code: error?.response?.status };
}

export const apiGET = async (endPoint ,id) => {
  try {
    const res = !!id ?  await API.get(endPoint+"/"+id) : await API.get(endPoint);
    return { data: res.data };
  } catch (error) {
    return error;
  }
};

export const apiPOST = async (endPoint, body) => {
  try {
    const res = await API.post(endPoint, body);
    return { data: res.data };
  } catch (error) {
    return error;
  }
};

export const apiPUT = async (endPoint, body) => {
  try {
    const res = await API.put(endPoint, body);
    return { data: res.data };
  } catch (error) {
    return error;
  }
};

export const apiPATCH = async (endPoint, body) => {
  try {
    const res = await API.patch(endPoint, body);
    return { data: res.data };
  } catch (error) {
    return error;
  }
};

export const apiDELETE = async (endPoint, body) => {
  try {
    const res = await API.delete(endPoint, { data: body });
    return { data: res.data };
  } catch (error) {
    return error;
  }
};
