import React from 'react';
import {useNavigate, NavLink, Link} from 'react-router-dom'

import logo from '../assets/images/logo.png'
import pay from '../assets/images/payments.png'

function Footer() {
	return(
		<div className="footer">
     
<div className="container">
<div className="row"> 
  <div className="col-md-3">
       <div className="item">
        <p className="ft-logo"><Link to="/"><img src={logo} className="App-logo" alt="logo" /></Link></p>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
    <p className="payment"><img src={pay}/></p>
    <p className="sec-pay">Secure Online Payments</p>
  </div>
  </div>
  <div className="col-md-3">
    <div className="item">
    <h3>Links</h3>
    <ul>
      <li><Link to="/">Home</Link></li>
      <li><Link to="/about">About</Link></li>
      <li><Link to="/fabrics">Fabrics</Link></li>
            <li><Link to="/about">Pages</Link></li>
      <li><Link to="/contact">Contact Us</Link></li>
    </ul>
  </div>
  </div>

  <div className="col-md-3">
    <div className="item">
    <h3>Informations</h3>
    <ul>
      <li><Link to="/about">Terms & Condition</Link></li>
      <li><Link to="/about">Return & Exchange</Link></li>
      <li><Link to="/about">Shipping & Delivery</Link></li>
      <li><Link to="/about">Private Policy</Link></li>
    </ul>
  </div>
  </div>

  <div className="col-md-3">
  <div className="item">
    <h3>Contact</h3>
    <p className="phone">Phone: +91 123456789</p>
    <p className="email">Email: contact@alqusoor.com</p>

  </div>
  </div>

</div>
</div>
     </div>
	)

}

export default Footer