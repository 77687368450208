import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Pagination, Navigation } from 'swiper/modules';

import gallery from "../data/gallery.json";

function Slider() {




	return(
		<div className="slider-wrap">


     





      <Swiper
        slidesPerView={3}
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >



        {gallery.map((gal,index) => (
            <SwiperSlide >
            <div className="inner">
                <img src={gal.url}/>
            </div>
            </SwiperSlide>
        ))}



      </Swiper>





 
		</div>
	)

}

export default Slider