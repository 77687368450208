import { useEffect} from 'react'

import map from '../assets/images/map.png'

function Contact(){

useEffect(() => {
   document.title = "Contact Us";

}, []);


    return (
        <div className="contact-page">

            <div className="page-title">
                <div className="container"></div>
            </div>


            <div className="container addr-wrap pt-40">

            <div className="col-2">
                <div className="item">

<div className="adr-wrap">
    <h3>Location</h3>
    <p>Ameerati Fashion<br/>
    Abu Dhabi, <br/>
    United Arab Emirates</p>

    <h3>Mail</h3>
    <p>alqusoorgroup@gmail.com</p>

    <h3>Contact</h3>
    <p>+971 55 669 2310</p>
</div>


                </div>                
                <div className="item">

<img src={map} className="mapimg" />

                </div>


            </div>

            </div>




   
        </div>
    )

}
export default Contact;