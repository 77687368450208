import { Navigate, useRoutes } from "react-router-dom";
import { useSelector } from "react-redux";
import ProtectedRoute from "./ProtectedRoute/ProtectedRoute";
// layouts
import Layouts from "./layouts/Layouts";
import Home from "./pages/Home";
import MyAccount from "./pages/Myaccount";
import Login from "./pages/Login";
import About from "./pages/about";
import Shop from "./pages/Shop";
import Size from "./pages/Size";
import Fabrics from "./pages/Fabrics";
import Contact from "./pages/contact";
import Cart from './pages/Cart';
import SizeChart from './pages/SizeChart';
// ----------------------------------------------------------------------

export default function Router() {
    const { isAuthenticated } = useSelector((state) => state.auth);
    const routes = useRoutes([
        // {
        //     path: "/user",
        //     element: (
        //         <ProtectedRoute
        //             isAuthenticated={isAuthenticated}
        //         >
        //             <Layouts />
        //         </ProtectedRoute>
        //     ),
        //     children: [
        //         { element: <Navigate to="/user" />, index: true },
        //         { path: "/", element: <Home /> },
        //         { path: "myaccount", element: <Myaccount /> },
        //         { path: "about", element: <About /> },
        //         { path: "shop", element: <Shop /> },
        //         { path: "size", element: <Size /> },
        //         { path: "fabrics", element: <Fabrics /> },
        //     ],
        // },

        {
            element: <Layouts />,
            children: [
                { path: "/", element: <Home /> },
                { path: "/login", element: <Login /> },
                { path: "/about", element: <About /> },
                { path: "/size", element: <Size /> },
                { path: "/fabrics", element: <Fabrics /> },
                { path: "/contact", element: <Contact /> },
                { path: "/cart", element: <Cart /> },
                { path: "/sizechart", element: <SizeChart /> },
                {
                    path: "/myaccount",
                    element: (
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                            <MyAccount />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: "/shop",
                    element: (
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                            <Shop />
                        </ProtectedRoute>
                    ),
                },
            ],
        },
        // {
        //     path: "*",
        //     element: <Navigate to="/404" replace />,
        // },
    ]);

    return routes;
}
