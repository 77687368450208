import axios from "axios";
import { baseUrl } from "../config";
import { store } from "../../redux/store";

const API = axios.create({ baseURL: baseUrl });

// API.interceptors.request.use((config) => {
//   let { token } = store.getState()?.auth;
//   if (token) {
//     config.headers["Authorization"] = `Bearer ${token}`;
//   }
//   return config;
// });

// API.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     if (error.response && error.response.status === 401) {
//       sessionStorage.removeItem("persist:root");
//       window.location.reload(false);
//     }
//     return Promise.reject(error);
//   }
// );

export default API;
