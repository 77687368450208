import {Link,NavLink } from 'react-router-dom';
import prdtImg from '../assets/images/product/prdt.jpg'
import { baseImage } from "../utils/config";
import axios from "axios";

function Cart(){
    
    
    const storedfabric = JSON.parse(localStorage.getItem("fabric"));
    const storedCollar = JSON.parse(localStorage.getItem("collar"));
    const storedTotal = JSON.parse(localStorage.getItem("total"));
    const storedMeasur = JSON.parse(localStorage.getItem("measurs"));
   const cartitem = JSON.parse(localStorage.getItem("cardata"));
   const productdetails = get_details(storedMeasur.age,storedfabric.fabid,storedCollar.clr_id);
  
   function get_details(age,fid,clrid){
    
      axios.get('https://back-end.ameeratifashions.com/api/product-details/'+age +'/'+fid+'/'+clrid+'?_format=json').then(response => {
       if (response?.data) {
        const data = response?.data ?? [];
         const featurs = {
              "cart":data
        };
        localStorage.setItem("cardata", JSON.stringify(featurs));
         return data;
       }
    });
     
   }
    return (
        <div className="cart-page">

            <div className="page-title">
                <div className="container"></div>
            </div>
            <div className='bread-wrap'>
                <div className='container'>
                    <ul className='breadcrumb'>
                        <li><NavLink activeclassname="active" to="/size"><b>1</b>Size</NavLink></li>
                        <li><NavLink activeclassname="active" to="/fabrics"><b>2</b>Fabrics</NavLink></li>
                        <li><NavLink activeclassname="active" to="/shop"><b>3</b>Collar</NavLink></li>
                        <li className="nolink"><NavLink activeclassname="active" to="/cart"><b>4</b>Cart</NavLink></li>
                    </ul>
                </div>
            </div>


            <div className="container pt-40">

            <div className="table strd-prdt">
                <div className="item str-img">
                    <div className="prdt-inner">
                        <img src={baseImage+storedfabric.faburl} className="main_img"/>
                        <img src={baseImage+storedCollar.clr_img} className="colar-img"/>
                        <Link className="edit-btn" to="/shop">Edit</Link>
                    </div>

                </div>

            <div className='strd-details'>

                <h2>Your <b>Frock</b></h2>

                <div className="item price-wrap">
                    <label>Price</label>
                    <b>{storedTotal.total}</b>
                </div>



                <div className="item">
                    <label>Fabrics</label>
                    <b>{cartitem?.cart[0].attribute_frock_color}</b>
                </div>
                <div className="item">
                    <label>Collar</label>
                    <b>{cartitem?.cart[0].attribute_collar}</b>
                </div>
                <div className="item">
                    <label>Age</label>
                    <b>{cartitem?.cart[0].attribute_demo_pizza_size}</b>
                </div> 
                <div className="item input-field full-width">
                    <label>Do you have more costomization in mind? Please mentionit below:</label>
                     <textarea id="notes" className="note-box" data-length="120"></textarea>
                </div>
                <div className="item">
                    <div><Link className="btn" to="/">CheckOut</Link></div>
                    <div><label for="checkpopup" className='btn'>Countinue shoping</label></div>
                </div>
            </div>
            <input type="checkbox" id="checkpopup"/>
            <div className='popup-wrap'>
                <div className='inner'>
                    <label for="checkpopup" className='close-btn'>+</label>

                    <div className='btn-wraper'>
                    <Link className="btn" to="/">Start over</Link>
                    <Link className="btn" to="/">Repeate your last order</Link>
                    </div>

                </div>
            </div>





            </div>









            </div>
        </div>
    )

}
export default Cart;