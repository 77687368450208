import React, { useEffect, useState } from "react";
import { Navigate, NavLink } from "react-router-dom";
import toast from "react-hot-toast";
import { frockcollarList } from "../utils/apis";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { frockFabricList } from "../utils/apis";
import { productList } from "../utils/apis";
import { baseImage } from "../utils/config";
const Shop = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [colar,setColar]= useState([]);
    const [colimg,setColImg] = useState([]);
   const [frockFabric, setFrockFabric] = useState([]);
    const [loading, setLoading] = useState(false);
     const [productDetails, setProductdetails] = useState([]);
     const [frockFabricImage,setFrockFabricImage]= useState([]);
    useEffect(() => {
        getData();
        getFrockFabric();
        let sizeitems = JSON.parse(localStorage.getItem('measurs'));
        let size = sizeitems?.age ?? 21;
        let fabricitems = JSON.parse(localStorage.getItem('fabric'));
        let fabid = fabricitems?.fabid ?? 6;
        let collaritems = JSON.parse(localStorage.getItem('collar'));
        let collarid = collaritems?.clr_id ??'all';
        getProductdetails(size,fabid,collarid);
        getFabricdetails(fabid);
        console.log(data);
        getColardetails();
    }, []);

     const getFrockFabric = async () => {
        setLoading(true);
        const res = await frockFabricList();
        if (res?.data) {
            setFrockFabric(res?.data ?? []);
        } else {
            toast.error(res?.data?.message);
        }
        setLoading(false);
    };
     const getColardetails = async () => {
         let collaritems = JSON.parse(localStorage.getItem('collar'));
         let collarid = collaritems?.clr_id ??'1';
         let colorimg = collaritems?.clr_img ??'/sites/default/files/styles/thumbnail/public/2023-11/col-1.png';
        setColar(collarid);
        setColImg(colorimg);
    }
    const getFabricdetails = async (fab) => {
         setLoading(true);
        await axios.get('https://back-end.ameeratifashions.com/api/fabricimage/'+fab +'?_format=json').then(response => {
         if (response?.data) {
            setFrockFabricImage(response?.data ?? []);
            //console.log( frockFabricImage[0].field_image);
         }
         else {
            toast.error(response?.data?.message);
        }
        setLoading(false);
      });   
    }
    const getProductdetails = async (size,fabid,collarid) => {
         setLoading(true);
      await axios.get('https://back-end.ameeratifashions.com/api/product-details/'+size +'/'+fabid+'/'+collarid+'?_format=json').then(response => {
         if (response?.data) {
            setProductdetails(response?.data ?? []);
            console.log(response)
            if(response.data.length > 0){
                console.log(response.data);
                let total = response?.data[0].price__number ?? 0;
                const featurs = {
                "total":total
            };
            localStorage.setItem("total", JSON.stringify(featurs));
            }
            else{
               let total = 0; 
               const featurs = {
                "total":total
              };
              localStorage.setItem("total", JSON.stringify(featurs));
            }
             
            
         }
         else {
            toast.error(response?.data?.message);
        }
        setLoading(false);
      });    

    }
     const addCart =() =>{
        /*const featurs = {
            "total":total
        };
        localStorage.setItem("total", JSON.stringify(featurs));*/
        navigate('/cart');
    }
    const getColar = (cid, name,colimg)=>{
        setColar(cid);
        setColImg(colimg);
        const featurs = {
            "clr_id":cid,
            "clr_img":colimg,
            "name":name,
        };
       localStorage.setItem("collar", JSON.stringify(featurs));
        let sizeitems = JSON.parse(localStorage.getItem('measurs'));
        let size = sizeitems?.age ?? 21;
        let fabricitems = JSON.parse(localStorage.getItem('fabric'));
        let fabid = fabricitems?.fabid ??6;
        let collaritems = JSON.parse(localStorage.getItem('collar'));
        let collarid = collaritems?.clr_id ??'all';
        getProductdetails(size,fabid,collarid);
    }

    const getData = async () => {
        setLoading(true);
        const res = await frockcollarList();
        if (res?.data) {
           
            setData(res?.data ?? []);
           
        } else {
            toast.error(res?.data?.message);
        }
        setLoading(false);
    };
    return (
        <div id="main-container">
            <div className="prdt-page">
                <div className="page-title">
                    <div className="container"></div>
                </div>

                <div className="bread-wrap">
                    <div className="container">
                        <ul className="breadcrumb">
                            <li>
                                <NavLink activeclassname="active" to="/size">
                                    <b>1</b>Size
                                </NavLink>
                            </li>
                            <li>
                                <NavLink activeclassname="active" to="/fabrics">
                                    <b>2</b>Fabrics
                                </NavLink>
                            </li>
                            <li>
                                <NavLink activeclassname="active" to="/shop">
                                    <b>3</b>Collar
                                </NavLink>
                            </li>
                            <li className="nolink">
                                <NavLink activeclassname="active" to="/cart">
                                    <b>4</b>Cart
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="prdt-create">
                    <div className="sidebar">
                        <h3>Collar</h3>
                        <ul className="inline">
                            {data.map((colar, index) => (

                                <li key={index} onClick={()=>getColar(colar.attribute_value_id, colar.name,colar.field_colla)}>
                                    <img
                                        src={
                                            "https://back-end.ameeratifashions.com/" +
                                            colar.field_colla
                                        }
                                        alt=""
                                    />
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="prdt-main">
                       <div className="prdt-inner">
                    {frockFabricImage.map((fabimg, fabimgindex) =>(
                    <> <img src= {baseImage +fabimg.field_image} 
                     className="main_img"/>
                     <img src={baseImage +colimg} className="colar-img"/></> 
                     

                    ))

                    }
                 
                    </div>

                    </div>
                    <div className="prdt-details">
                       {productDetails.map((prod, index) => (
                       <><h4>{prod.title}</h4>  
                        <p>Size : <b>{prod.attribute_demo_pizza_size} </b></p>
                        <p>Fabric : <b>{prod.attribute_frock_color}</b></p>
                        <p>Collar : <b>{prod.attribute_collar}</b></p>
                        <p>Price : <b>{prod.price__number}</b></p>  </>                   
                    ))}
                    <button onClick={addCart}>NEXT</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Shop;
