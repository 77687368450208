import { useEffect, useState } from "react";
import { useNavigate, Link, NavLink } from "react-router-dom";
import toast from "react-hot-toast";
import {
    frockFabricList,
    frockSizeList,
    productImageList,
} from "../utils/apis";
import { baseImage } from "../utils/config";

const Size = () => {
    const navigate = useNavigate();

    const [productImage, setProductImage] = useState([]);
    const [frockSize, setFrockSize] = useState([]);
    const [frockFabric, setFrockFabric] = useState([]);
    const [age, setAge] = useState(5);
    const [size, setSize] = useState(35);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getFrockSize();
        getFrockFabric();
        getProductImage();
    }, []);

    const getFrockSize = async () => {
        setLoading(true);
        const res = await frockSizeList();
        if (res?.data) {
            setFrockSize(res?.data ?? []);
        } else {
            toast.error(res?.data?.message);
        }
        setLoading(false);
    };

    const getFrockFabric = async () => {
        setLoading(true);
        const res = await frockFabricList();
        if (res?.data) {
            setFrockFabric(res?.data ?? []);
        } else {
            toast.error(res?.data?.message);
        }
        setLoading(false);
    };

    const getProductImage = async () => {
        setLoading(true);
        const res = await productImageList();
        if (res?.data) {
            setProductImage(res?.data ?? []);
        } else {
            toast.error(res?.data?.message);
        }
        setLoading(false);
    };

    const onValueChange = (event) => {
        event.preventDefault();
        setAge(event.target.value);
    };

    const SizeAdd = (event) => {
        event.preventDefault();
        const featurs = {
            age: age,
            size: size,
        };
        console.log(featurs);
        localStorage.setItem("measurs", JSON.stringify(featurs));
        navigate("/fabrics");
    };

    

    return (
        <div className="size-page blue-bg">
            <div className="page-title">
                <div className="container">{/* <h1>Size</h1> */}</div>
            </div>

            <div className="bread-wrap">
                <div className="container">
                    <ul className="breadcrumb">
                        <li>
                            <NavLink activeclassname="active" to="/size">
                                <b>1</b>Size
                            </NavLink>
                        </li>
                        <li>
                            <NavLink activeclassname="active" to="/fabrics">
                                <b>2</b>Fabrics
                            </NavLink>
                        </li>
                        <li>
                            <NavLink activeclassname="active" to="/shop">
                                <b>3</b>Collar
                            </NavLink>
                        </li>
                        <li className="nolink">
                            <NavLink activeclassname="active" to="/cart">
                                <b>4</b>Cart
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="size_wraper">
                <div className="container pt-40">
                    <div className="size-wrap">
                        <div className="str-prdt">
                            <div className="prdt-inner">
                                {productImage.map((item, i) => (
                                    <>
                                        <div key={i}>
                                            <img
                                                src={
                                                    baseImage + item.field_image
                                                }
                                                alt=""
                                                className="main_img"
                                            />
                                            <img
                                                src={
                                                    baseImage + item.field_colla
                                                }
                                                alt=""
                                                className="colar-img"
                                            />
                                        </div>
                                    </>
                                ))}
                            </div>
                        </div>

                        <div className="astr-prdtsd middle">
                            <form onSubmit={SizeAdd}>
                                <div className="form-item check-wrap">
                                    <label>Please select an age</label>
                                    <div className="size-radio">
                                        {frockSize.map((item, i) => (
                                            <label key={i}>
                                                <input
                                                    name="size"
                                                    type="radio"
                                                    value={item.attribute_value_id}
                                                    onChange={onValueChange}
                                                    checked={age === item.attribute_value_id}
                                                />
                                                <b>{item.name}</b>
                                            </label>
                                        ))}
                                    </div>
                                </div>

                                <p>
                                    Unsure about sizes? Please check our{" "}
                                    <Link to="/size">size references</Link>
                                </p>
                                <div className="form-action right-align">
                                    <button>NEXT</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Size;
