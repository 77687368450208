import { useEffect, useState } from "react";
import { useNavigate, Link, NavLink } from "react-router-dom";
import toast from "react-hot-toast";
import { frockFabricList } from "../utils/apis";
import { productList } from "../utils/apis";
import { baseImage } from "../utils/config";
import axios from "axios";
const Fabrics = () => {
     const navigate = useNavigate();
    const [frockFabric, setFrockFabric] = useState([]);
    const [frockFabricImage,setFrockFabricImage]= useState([]);
    const [productDetails, setProductdetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [fab, setFab] = useState(7);
    const [fabPrice, setFabPrice]= useState('8');
    const [fabUrl, setFabUrl]= useState('/sites/default/files/styles/thumbnail/public/2023-11/fab-1.png?itok=iYpxTNbg');
    useEffect(() => {
        getFrockFabric();
        let sizeitems = JSON.parse(localStorage.getItem('measurs'));
        let size = sizeitems?.age ?? '21';
        let fabricitems = JSON.parse(localStorage.getItem('fabric'));
        let fabid = fabricitems?.fabid ?? 6;
        let collaritems = JSON.parse(localStorage.getItem('collar'));
        let collarid = collaritems?.clr_id ??'all';
        getProductdetails(size,fabid,collarid);
        getFabricdetails(fabid);
    }, []);
    const getProductdetails = async (size,fabid,collarid) => {
         setLoading(true);
      await axios.get('https://back-end.ameeratifashions.com/api/product-details/'+size +'/'+fabid+'/'+collarid+'?_format=json').then(response => {
         if (response?.data) {
            console.log(size);
            setProductdetails(response?.data ?? []);
         }
         else {
            toast.error(response?.data?.message);
        }
        setLoading(false);
      });    

    }
    const getFabricdetails = async (fab) => {
         setLoading(true);
        await axios.get('https://back-end.ameeratifashions.com/api/fabricimage/'+fab +'?_format=json').then(response => {
         if (response?.data) {
            setFrockFabricImage(response?.data ?? []);
            //console.log( frockFabricImage[0].field_image);
         }
         else {
            toast.error(response?.data?.message);
        }
        setLoading(false);
      });   
    }
    const nextPage =() =>{
        navigate('/shop');
    }
    const getFrockFabric = async () => {
        setLoading(true);
        const res = await frockFabricList();
        if (res?.data) {
            setFrockFabric(res?.data ?? []);
        } else {
            toast.error(res?.data?.message);
        }
        setLoading(false);
    };
    const getFab = (fid,fUrl)=>{
            setFab(fid);
            setFabUrl(fUrl);
            const featurs = {
              "fabid":fid,
              "faburl":fUrl,
            };
        localStorage.setItem("fabric", JSON.stringify(featurs));
        getFabricdetails(fid);
        let sizeitems = JSON.parse(localStorage.getItem('measurs'));
        let size = sizeitems?.age??'21';
        let collaritems = JSON.parse(localStorage.getItem('collar'));
        let cid = collaritems?.clr_id ??'all';
        getProductdetails(size,fid,cid);
    };

    return (
        <div className="prdt-page">
            <div className="page-title">
                <div className="container"></div>
            </div>

            <div className="bread-wrap">
                <div className="container">
                    <ul className="breadcrumb">
                        <li>
                            <NavLink activeclassname="active" to="/size">
                                <b>1</b>Size
                            </NavLink>
                        </li>
                        <li>
                            <NavLink activeclassname="active" to="/fabrics">
                                <b>2</b>Fabrics
                            </NavLink>
                        </li>
                        <li>
                            <NavLink activeclassname="active" to="/shop">
                                <b>3</b>Collar
                            </NavLink>
                        </li>
                        <li className="nolink">
                            <NavLink activeclassname="active" to="/cart">
                                <b>4</b>Cart
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="prdt-create">
                <div className="sidebar">
                    <div className="fabric-wrap block">
                        <h3>Fabrics</h3>
                        <ul className="inline fab-list">
                            {frockFabric.map((fab, index) => (
                                <li
                                    onClick={() =>getFab(
                                            fab.attribute_value_id,
                                            fab.field_fabric_image
                                        )
                                    }
                                    key={index}
                                >
                                    <div className="inner">
                                        <img
                                            src={
                                                baseImage +
                                                fab.field_fabric_image
                                            }
                                            alt=""
                                        />
                                        <span>{fab.name}</span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                 
                <div className="prdt-main">
                    <div className="prdt-inner">
                    {frockFabricImage.map((fabimg, fabimgindex) =>(
                     <img src= {baseImage +fabimg.field_image} className="main_img"/>
                     

                    ))

                    }
                 
                    </div>
                </div>

                <div className="prdt-details">
                  {productDetails.map((prod, index) => (
                       <><h4>{prod.title}</h4>  
                        <p>Size : <b>{prod.attribute_demo_pizza_size} </b></p>
                        <p>Fabric : <b>{prod.attribute_frock_color}</b></p>
                        <p>Collar : <b>{prod.attribute_collar}</b></p> 
                        <p>Price : <b>{prod.price__number}</b></p> </>                   
                    ))}
                     
                    <div className="btn-wrap"></div>
                     <div className='btn-wrap'>
                    <button onClick={nextPage}>NEXT</button>
                </div>
                </div>
            </div>
             
        </div>
    );
};

export default Fabrics;
