import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Toaster } from "react-hot-toast";
import "./App.css";
import Router from "./routes";
import ReduxProvider from "./redux/store";

function App() {
    return (
        <>
            <ReduxProvider>
                <Router />
            </ReduxProvider>
            <Toaster position="top-center" reverseOrder={false} />
        </>
    );
}

export default App;
