import { api } from "./apis";
import { apiGET, apiPOST, apiDELETE, apiPUT } from "./handler";

// ALL Device APIs
export const frockcollarList = () => apiGET(api.frockcollar_list);
export const frockSizeList = () => apiGET(api.frockSize);
export const frockFabricList = () => apiGET(api.frockFabric);
export const productImageList = () => apiGET(api.productImage);
export const productList = () => apiGET(api.productImage);

