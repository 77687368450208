import React from 'react'

function SizeChart() {
  return (
    <div className='sizehart-page'>
      <div className="page-title">
        <div className="container"></div>
      </div>




      <div className='pt-40'>
        <div className='container'>


        <p>Please note that if your child is in between sizes, it is better to choose bigger size to allow for growth. Table below indicates measurements of dresses for different ages. Choose your size based on measurements, NOT age. Age is only approximate.</p>

        <div className='size-table'>
          
              <table cellspacing="0">
                <tbody>
                  <tr>
                    <th>Age</th>
                    <th>Length of dress(cm)</th>

                  </tr>
                  <tr>
                    <td>6m</td>
                    <td>46</td>
                  </tr>
                  <tr>
                    <td>8m</td>
                    <td>51</td>
                  </tr>
                  <tr>
                    <td>10m</td>
                    <td>56</td>
                  </tr>
                  <tr>
                    <td>1year</td>
                    <td>62</td>
                  </tr>
                  <tr>
                    <td>1.5 year</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>2year</td>
                    <td>72</td>
                  </tr>
                  <tr>
                    <td>3year</td>
                    <td>77</td>
                  </tr>
                  <tr>
                    <td>3.5year</td>
                    <td>82</td>
                  </tr>
                  <tr>
                    <td>4year</td>
                    <td>87</td>
                  </tr>
                  <tr>
                    <td>5year</td>
                    <td>92</td>
                  </tr>
                  <tr>
                    <td>6year</td>
                    <td>97</td>
                  </tr>
                  <tr>
                    <td>7year</td>
                    <td>102</td>
                  </tr>
                  <tr>
                    <td>8year</td>
                    <td>107</td>
                  </tr>
                  <tr>
                    <td>9year</td>
                    <td>112</td>
                  </tr>
                  <tr>
                    <td>10year</td>
                    <td>117</td>
                  </tr>
                  <tr>
                    <td>11year</td>
                    <td>122</td>
                  </tr>
                  <tr>
                    <td>12year</td>
                    <td>127</td>
                  </tr>
                  <tr>
                    <td>13year</td>
                    <td>133</td>
                  </tr>
                </tbody>
              </table>

          </div>
        </div>

      </div>

    </div>
  )
}

export default SizeChart