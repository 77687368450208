const api = {
  // Manage Device
  frockcollar_list: "api/frockcollar?_format=json",
  frockSize: "api/frocksize?_format=json",
  frockFabric: "api/frockfabric?_format=json",
  productImage: "api/product-image?_format=json",
  productList: "api/product-details/size?_format=json",
};

export { api };
